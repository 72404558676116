import { FAILED_TO_FETCH } from './messages';

export const translateError = (err: unknown) => {
  const DEFAULT_MESSAGE = 'Ocurrió un error.';
  if (err instanceof Error) {
    switch (err.message) {
      case 'Failed to fetch':
        return FAILED_TO_FETCH;
      //break;
      default:
        return err.message;
      //break;
    }
  }
  if (typeof err === 'string') {
    return err;
  }
  return DEFAULT_MESSAGE;
}

/*export const fetchWithRetryAndTimeout = (
  url: string,
  options: any,
  retries = 0,
  timeout = 90000
): Promise<Response> => {
  return new Promise((resolve, reject) => {
    const retryFetch = (retryCount: number) => {
      fetch(url, options)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response error");
          }
          return response
        })
        .then((data) => resolve(data))
        .catch((error) => {
          if (retryCount > 0) {
            setTimeout(() => {
              retryFetch(retryCount - 1);
            }, timeout);
          } else {
            reject(error);
          }
        });
    };

    retryFetch(retries);
  });
};*/